"use client";

import { SiteView } from "../../../../components/site/SiteView";
import { SanityDataWithPreviewConfig } from "../../../sanityFetcher/getAppFolderPageProps";
import { SanityPreviewLiveQuery } from "../../../sanityFetcher/SanityPreviewLiveQuery";
import { FrontPageData } from "./page";

type Props = {
  sanityData: SanityDataWithPreviewConfig<FrontPageData>;
};

export const ClientSideFrontPage = (props: Props) => (
  <SanityPreviewLiveQuery sanityData={props.sanityData}>{(data) => <SiteView {...data} />}</SanityPreviewLiveQuery>
);
