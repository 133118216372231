"use client";

import { createContext, ReactNode, useContext, useReducer } from "react";
import { SanityDataWithPreviewConfig } from "../../../../sanityFetcher/getAppFolderPageProps";

type SanityData = SanityDataWithPreviewConfig<any>;

type DeveloperMetricsContext = {
  sanityDataEntries: SanityData[];
  addDataEntry: (sanityData: SanityData) => void;
};

const Context = createContext<DeveloperMetricsContext>({ sanityDataEntries: [], addDataEntry: () => null });

export const useDeveloperMetricsContext = () => useContext(Context);

export const DeveloperMetricsProvider = (props: { children: ReactNode }) => {
  const [sanityDataEntries, dispatch] = useReducer(
    (state: SanityData[], newData: SanityData) => [
      ...state.filter((entry) => entry._previewConfig?.query !== newData._previewConfig?.query),
      newData,
    ],
    [] as SanityData[]
  );

  return <Context.Provider value={{ sanityDataEntries, addDataEntry: dispatch }}>{props.children}</Context.Provider>;
};
